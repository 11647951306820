.navbar.bg-inverse {
	.nav.navbar-nav {
		justify-content: space-between !important;
	}
	@include media-breakpoint-only("md") {
		.navbar-nav .nav-item + .nav-item {
			margin-left: inherit;
		}

		.navbar-nav .nav-link {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			white-space: inherit;
		}
	}

	@include media-breakpoint-up("lg") {
		.navbar-nav .nav-item + .nav-item {
			margin-left: 0;
		}

		.navbar-nav .nav-link {
			padding: 0.8rem;
			white-space: nowrap;
		}
	}
}

@include media-breakpoint-only("md") {
	.nav-item > .nav-link {
		color: inherit;
	}
}

.main-menu.menu-toggleable-left {
	@include media-breakpoint-down("md") {
		background-color: $white;
        z-index: 1010;
	}
}

.menu-toggleable-left {
	.close-menu {
		@include media-breakpoint-down("md") {
			display: block;
		}
	}

	@include media-breakpoint-down("md") {
		.bg-inverse {
			background-color: white !important;
			color: $grey7;
		}
	}

	&.in {
		@include media-breakpoint-down("sm") {
			right: 0;
			margin-right: 1.25em; /* 20/16 */
		}

		@include media-breakpoint-down("md") {
			.nav-item + .nav-item {
				border-top: 1px solid $grey2;
			}

			.dropdown {
				display: block;
				position: static;
			}

			.dropdown-toggle {
				&::after {
					@include caret-right();
				}
			}

			.dropdown-menu {
				position: absolute;
				left: -100%;
				top: 0;
				width: 100%;
				height: 100vh;
				border: 0 none;
				transition: $slide-out-animation;
				display: block;
			}

			.show > .dropdown-menu {
				left: 0 !important;
			}
		}
	}
}

.multilevel-dropdown {
	.dropdown-menu {
		@include media-breakpoint-only("md") {
			box-shadow: inherit;
		}
		@include media-breakpoint-up("lg") {
			box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
		}
	}

	.dropdown-menu > .dropdown > .dropdown-menu {
		@include media-breakpoint-down("md") {
			top: 0;
			left: -100%;

			.navbar > .close-menu > .back {
				display: none;
			}
		}

		@include media-breakpoint-up("lg") {
			top: -0.65em;
			left: 99%;
		}
	}

    .dropdown-left {
        @include media-breakpoint-up("lg") {
            &.dropdown-menu:not(.main-edge-dropdown) {
                right: 99%;
                left: auto;
            }
        }
    }

    .main-edge-dropdown {
        @include media-breakpoint-up("lg") {
            right: 0;
            left: auto;
        }
    }

	.dropdown-item.top-category > .nav-link {
		padding-left: 0 !important;
	}
}
