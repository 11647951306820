.product-tile-container {
	.product-tile-container-border {
		position: absolute;
		top: 5%;
		right: 0;
		width: 1px;
		height: 85%;
		background-color: $grey3;
	}

	@include media-breakpoint-down(xs) {
		&:nth-child(even) .product-tile-container-border {
			display: none;
		}
	}

	@include media-breakpoint-up(sm) {
		&:nth-child(3n+0) .product-tile-container-border {
			display: none;
		}
	}

	.swatches a {
		border-bottom: 0;
		text-decoration: none;

		.swatch-circle.m-disabled::before {
			background-color: $grey4;
			border: 1px solid $white;
			border-radius: 50px;
			display: block;
			content: "";
			height: 3px;
			left: -3px;
			position: absolute;
			top: 15px;
			transform: rotate(45deg);
			width: 37px;
		}
	}
}

.carousel-item:current {
	border: 1px solid red;
}
